<template>
  <div class="box-content">
    <span>Liste des souscriptions</span>
    <mcp-modal :show="show" @closeModal="showModal">
      <add-subscription
        @closeModal="showModal"
        :operators="operators"
        :ratings="ratings"
        :permissions="permissions"
        :callBackFunction="getSubscriptions"
      />
    </mcp-modal>
    <mcp-modal :show="edit" @closeModal="showEdit">
      <edit-subscription
        @closeModal="showEdit"
        :operators="operators"
        :ratings="ratings"
        :permissions="permissions"
        :current="current"
        :callBackFunction="getSubscriptions"
      />
    </mcp-modal>
    <search :makeSearch="makeSearch">
      <template #buttons>
        <button @click="showAdd" class="button is-outlined is-small">
          Ajouter une souscription
        </button>
        <button
          @click="refreshSouscription"
          class="button is-outlined is-small"
        >
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="subscriptions.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(item, key) in subscriptions" :key="key">
            <td>{{ item.service.replace("-", " ") }}</td>
            <td>{{ item.service }}</td>
            <td>{{ item.bank }}</td>
            <td>{{ item.rating }}</td>
            <td>
              <span v-if="item.active" class="tag is-success is-light"
                >Actif</span
              >
              <span v-else class="tag is-primary is-light">Inactif</span>
            </td>
            <td>{{ item.createdate }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Supprimer">
                  <i
                    @click="removeSubscription(item)"
                    class="fa-solid fa-remove"
                  ></i>
                </mcp-tooltip>
                <mcp-tooltip title="Modifier">
                  <i @click="showEdit(item)" class="fa-solid fa-edit"></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import McpModal from "@/components/McpModal";
import addSubscription from "./add";
import editSubscription from "./edit";
export default {
  name: "souscription",
  components: {
    dataTable,
    search,
    McpModal,
    addSubscription,
    editSubscription,
  },
  data: () => ({
    loading: false,
    show: false,
    edit: false,
    current: {},
    columns: [
      { name: "service", label: "Nom du service" },
      { name: "code", label: "Code du service" },
      { name: "bank", label: "Opérateur" },
      { name: "rating", label: "Tarification" },
      { name: "etat", label: "Status" },
      { name: "created_at", label: "Date de création" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      service: "",
      search: "",
    },
  }),

  computed: {
    ...mapGetters({
      subscriptions: `${TYPES.modules.SUBSCRIPTIONS}/${TYPES.getters.GET_SUBSCRIPTIONS}`,
      meta: `${TYPES.modules.SUBSCRIPTIONS}/${TYPES.getters.GET_SUBSCRIPTIONS_META}`,
      permissions: `${TYPES.modules.UTILS}/${TYPES.getters.GET_PERMISSIONS}`,
      ratings: `${TYPES.modules.UTILS}/${TYPES.getters.GET_RATING}`,
      operators: `${TYPES.modules.UTILS}/${TYPES.getters.GET_OPERATOR}`,
    }),
  },

  mounted() {
    this.getSubscriptions();
  },

  methods: {
    navigationFunction(page) {
      this.current.page = page;
      this.getSubscriptions();
    },

    showModal() {
      this.show = !this.show;
    },

    showAdd() {
      this.showModal();
      this.getPermissions();
      this.getRatings();
      this.getOperators();
    },

    getSubscriptions() {
      this.loading = true;
      this.model.service = this.$route.params.id;
      this.$store
        .dispatch(
          `${
            TYPES.modules.SUBSCRIPTIONS + "/" + TYPES.actions.GET_SUBSCRIPTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.page = 1;
      this.model.search = search;
      this.loading = true;
      this.model.service = this.$route.params.id;
      this.$store
        .dispatch(
          `${
            TYPES.modules.SUBSCRIPTIONS + "/" + TYPES.actions.GET_SUBSCRIPTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshSouscription() {
      this.loading = true;
      this.model.page = 1;
      this.model.search = "";
      this.model.service = this.$route.params.id;
      this.$store
        .dispatch(
          `${
            TYPES.modules.SUBSCRIPTIONS + "/" + TYPES.actions.GET_SUBSCRIPTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeSubscription(souscription) {
      if (confirm("Voulez vous supprimer la souscription ?")) {
        this.loading = true;
        this.$store
          .dispatch(
            `${
              TYPES.modules.SUBSCRIPTIONS +
              "/" +
              TYPES.actions.DELETE_SUBSCRIPTION
            }`,
            souscription
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`La souscription a été supprimé`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    getPermissions() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_PERMISSIONS}`
      );
    },

    getRatings() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_RATING}`
      );
    },

    getOperators() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_OPERATOR}`
      );
    },

    showModalEdit() {
      this.edit = !this.edit;
    },

    showEdit(item) {
      this.current = item;
      this.showModalEdit();
      this.getPermissions();
      this.getRatings();
      this.getOperators();
    },
  },
};
</script>
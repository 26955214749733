<template>
  <div>
    <h2>Modifier une souscription</h2>
    <spinner v-if="status" :show="true" color="#CF0C1A" />
    <div v-else>
      <div class="pt-10">
        <label>Opérateurs</label>
        <v-select
          :options="operators"
          placeholder="Selectionner"
          v-model="form.bank_id"
          label="bankcode"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.bank_id"
          :class="errors.bank_id.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.bank_id[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <label>Facturation</label>
        <v-select
          :options="ratings"
          placeholder="Selectionner"
          v-model="form.rating_id"
          label="label"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.rating_id"
          :class="errors.rating_id.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.rating_id[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <label>Permissions</label>
        <v-select
          :options="permissions"
          multiple
          placeholder="Selectionner"
          v-model="form.permissions"
          label="description"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.permissions"
          :class="errors.permissions.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.permissions[0]
          }}</strong>
        </div>
      </div>
      <div class="pt-10">
        <label class="checkbox"
          ><input v-model="form.active" type="checkbox" /> Activer</label
        >
      </div>
      <div class="pt-10">
        <label class="checkbox"
          ><input v-model="form.ova" type="checkbox" /> Existe un ova pour cette
          souscription ?</label
        >
      </div>
      <div v-if="form.ova">
        <div class="pt-10">
          <text-input
            type="text"
            :errors="errors.account"
            v-model="form.account"
            label="Numéro de compte"
          />
        </div>
        <div class="pt-10">
          <text-input
            type="text"
            :errors="errors.username"
            v-model="form.username"
            label="Nom d'utilisateur"
          />
        </div>
        <div class="pt-10">
          <text-input
            :errors="errors.password"
            v-model="form.password"
            label="Mot de passe du compte"
          />
        </div>
        <div class="pt-10">
          <text-area-input
            :errors="errors.description"
            v-model="form.description"
            label="Description"
            placeholder="Description"
          />
        </div>
      </div>
      <div class="pt-10 d-flex-right">
        <button
          @click="editSubscription"
          :disabled="loading"
          :class="
            loading
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput";
import Spinner from "@/components/Spinner";
import { TYPES } from "@/store/types";
import TextAreaInput from "@/components/TextAreaInput";
const Default_form = {
  bank_id: "",
  rating_id: "",
  service_id: "",
  active: true,
  permissions: [],
  ova: false,
  account: "",
  username: "",
  password: "",
  description: "",
};
export default {
  name: "editSubscription",
  components: { TextInput, Spinner, TextAreaInput },
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
    ratings: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Object,
      default: () => {},
    },
    callBackFunction: {
      type: Function,
    },
  },

  data: () => ({
    errors: [],
    loading: false,
    form: {
      id: "",
      bank_id: "",
      rating_id: "",
      service_id: "",
      active: true,
      permissions: [],
      ova: false,
      account: "",
      username: "",
      password: "",
      description: "",
    },
  }),

  computed: {
    status() {
      if (
        this.permissions.length === 0 ||
        this.operators.length === 0 ||
        this.ratings.length === 0
      ) {
        return true;
      } else return false;
    },
  },

  mounted() {
    this.form.id = this.current.id;
    this.form.bank_id = this.current.bank_id;
    this.form.rating_id = this.current.rating_id;
    this.form.service_id = this.$route.params.id;
    this.form.active = this.current.active;
    this.form.permissions = this.pluck(
      this.current.permissions,
      "permission_id"
    );
    this.form.ova = this.current.account != "" ? true : false;
    this.form.account = this.current.account;
    this.form.username = this.current.username;
    this.form.password = this.current.password;
    this.form.description = this.current.description;
  },

  methods: {
    pluck(arr, key) {
      return arr.map((i) => i[key]);
    },

    editSubscription() {
      this.loading = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.SUBSCRIPTIONS +
            "/" +
            TYPES.actions.UPDATE_SUBSCRIPTION
          }`,
          this.form
        )
        .then((res) => {
          this.errors = [];
          this.loading = false;
          this.form = { ...Default_form };
          this.$emit("closeModal");
          this.callBackFunction();
          this.$toast.success(`Opération effectuée`);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>